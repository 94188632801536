import React, { lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  withRouter,
  BrowserRouter,
  useHistory
} from "react-router-dom";

import { observer, inject } from "mobx-react";

import LoadingComponent from "../components/LoadingComponent/LoadingComponent";
import BodyComponent from "../components/BodyComponent/BodyComponent";
import HeaderComponent from "../components/HeaderComponent/HeaderComponent";
import { Toaster } from "react-hot-toast";
import FooterComponent from "../components/FooterComponent/FooterComponent";

const HomePage = lazy(() => import("./HomePage"));
const ConfigPage = lazy(() => import("./ConfigPage"));
const QrCodeAllocatePage = lazy(() => import("./QrCodeAllocatePage"));
const ExternalCodeAllocatePage = lazy(() =>
  import("./ExternalCodeAllocatePage")
);
const SelectOrganizationPage = lazy(() => import("./SelectOrganizationPage"));
const SearchOrganizationFinalNodePage = lazy(() =>
  import("./SearchOrganizationFinalNodePage")
);
const SelectCustomerPage = lazy(() => import("./SelectCustomerPage"));
const AllocateContainerPage = lazy(() => import("./AllocateContainerPage"));
const AllocateContainerPreRegisteredPage = lazy(() =>
  import("./AllocateContainerPreRegisteredPage")
);
const AllocateContainerWithPhotoPage = lazy(() =>
  import("./AllocateContainerWithPhotoPage")
);
const PreRegisteredAllocatePage = lazy(() =>
  import("./PreRegisteredAllocatePage")
);

const QrCodeDeallocatePage = lazy(() => import("./QrCodeDeallocatePage"));
const DeallocateContainerPage = lazy(() => import("./DeallocateContainerPage"));

const QrCodeReversePage = lazy(() => import("./QrCodeReversePage"));
const ReverseListPage = lazy(() => import("./ReverseListPage"));
const ReverseContainerPage = lazy(() => import("./ReverseContainerPage"));

const ContainerTestsPage = lazy(() => import("./ContainerTestsPage"))

export default (props) => {

  return (
    <BrowserRouter>
      <HeaderComponent />
      <BodyComponent>
        <ConfigRoutes {...props} />
        <Toaster />
      </BodyComponent>
      <FooterComponent />
    </BrowserRouter>
  );
};

const ConfigRoutes = inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/" render={() => <HomePage {...props} />} />
            <Route path="/config" render={() => <ConfigPage {...props} />} />
            <Route path="/container-tests" render={() => <ContainerTestsPage {...props} />} />

            <PrivateRoutes {...props} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      );
    })
  )
);

//rotas privadas que é possível acessar apenas com usuário logado.
const PrivateRoutes = (props) => {
  return (
    <React.Fragment>
      {/* ALLOCATION FLOW */}
      <Route
        exact
        path="/qr-code-allocate"
        render={() => <QrCodeAllocatePage {...props} />}
      />
      <Route
        exact
        path="/external-code-allocate"
        render={() => <ExternalCodeAllocatePage {...props} />}
      />
      <Route
        exact
        path="/pre-registered-allocate"
        render={() => <PreRegisteredAllocatePage {...props} />}
      />
      <Route
        exact
        path="/select-organization/organization/:uuidOrganization"
        render={() => <SelectOrganizationPage {...props} />}
      />
      <Route
        exact
        path="/search-organization-final-node/organization/:uuidOrganization"
        render={() => <SearchOrganizationFinalNodePage {...props} />}
      />
      <Route
        exact
        path="/select-customer/organization/:uuidOrganization"
        render={() => <SelectCustomerPage {...props} />}
      />
      <Route
        exact
        path="/allocate-container"
        render={() => <AllocateContainerPage {...props} />}
      />
      <Route
        exact
        path="/allocate-container-pre-registred/allocation/:uuidAllocation"
        render={() => <AllocateContainerPreRegisteredPage {...props} />}
      />
      <Route
        exact
        path="/allocate-container-with-photo"
        render={() => <AllocateContainerWithPhotoPage {...props} />}
      />

      {/* DEALLOCATION FLOW */}
      <Route
        exact
        path="/qr-code-deallocate"
        render={() => <QrCodeDeallocatePage {...props} />}
      />

      <Route
        exact
        path="/deallocate-container/allocation/:uuidAllocation"
        render={() => <DeallocateContainerPage {...props} />}
      />

      {/* REVERSE FLOW */}
      <Route
        exact
        path="/qr-code-reverse"
        render={() => <QrCodeReversePage {...props} />}
      />

      <Route
        exact
        path="/reverse-list"
        render={() => <ReverseListPage {...props} />}
      />
      <Route
        exact
        path="/reverse-container/allocation/:uuidAllocation"
        render={() => <ReverseContainerPage {...props} />}
      />
    </React.Fragment>
  );
};
